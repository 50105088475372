.App {
  max-width: 990px;
  margin: 0 auto;
}

.App .loader {
  text-align: center;
  margin: 3em 0;
}

.logoBox {
  display: flex;
  align-items: center;
  padding: 8px;
}

.logoBox svg {
  fill: #32325d;
  width: 35px;
}

.logoText {
  font-size: 33px;
  color: #32325d;
  font-weight: 500;
}

.locationBox {
  display: flex;
  align-items: center;
}

.refresh {
  display: flex;
  align-items: center;
  padding: 0px 8px;
  cursor: pointer;
}

.refresh svg {
  width: 1.5em;
  fill: #32325d;
}

.locationSelector {
  padding: 8px;
  width: 100%;
}

.geolocationError {
  margin: 3em 0;
  text-align: center;
}

.geolocationError svg {
  width: 5em;
  fill: #32325d;
}

.displayBlock {
  margin: 8px 8px;
  padding: 12px 8px 12px 8px;
  min-width: 290px;
  border-radius: 15px;
  background-color: #ffffff;
}

.bigMetricBox {
  text-align: center;
  min-height: 80px;
}

.bigMetric {
  font-size: 26px;
}

.bigMetricBox .description {
  font-size: 14px;
}

/*
Fix for blocked scroll from https://github.com/FormidableLabs/victory/issues/1430
*/
.VictoryContainer {
  touch-action: auto !important;
}
