.arrow-icon {
    display: inline-flex;
    align-self: center;
}

.arrow-icon svg {
    height: 0.91em;
    fill: currentColor;
}

.arrow-icon.baseline svg {
    top: 0.14em;
    position: relative;
}
