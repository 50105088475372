.aboutBlock {
    padding: 12px;
}

.aboutBlock .about {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}

.aboutBlock .about svg {
    padding-right: 10px;
    width: 30px;
    fill: #32325d;
}