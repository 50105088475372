body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background: linear-gradient(to left top, #cadbed, #f6f9fc) fixed;
}

html, button, input, select, textarea, .pure-g [class *= "pure-u"] {
  font-family: Camphor,Open Sans,Segoe UI,sans-serif;
  color: #525f7f;
}
